var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('div', [_vm._m(0), _c('ul', {
    staticClass: "nav nav-tabs nav-tabs-alt",
    attrs: {
      "role": "tablist"
    }
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('button', {
    staticClass: "nav-link active",
    attrs: {
      "id": "tab-btn-company-".concat(_vm.supplier._id),
      "data-toggle": "tab",
      "data-target": "#tab-company-".concat(_vm.supplier._id),
      "role": "tab",
      "aria-selected": "true",
      "aria-controls": "#tab-company-".concat(_vm.supplier._id)
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-users"
  }), _vm._v(" Company ")])]), _c('li', {
    staticClass: "nav-item ms-auto"
  }, [_c('button', {
    staticClass: "nav-link",
    attrs: {
      "id": "tab-btn-account-".concat(_vm.supplier._id),
      "data-toggle": "tab",
      "data-target": "#tab-account-".concat(_vm.supplier._id),
      "role": "tab"
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-gauge"
  }), _vm._v(" Accounts ")])]), _c('li', {
    staticClass: "nav-item"
  }, [_vm.$auth.isAdmin ? _c('button', {
    staticClass: "nav-link",
    attrs: {
      "id": "tab-btn-global-".concat(_vm.supplier._id),
      "data-toggle": "tab",
      "data-target": "#tab-global-".concat(_vm.supplier._id),
      "aria-controls": "#tab-global-".concat(_vm.supplier._id),
      "role": "tab"
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-globe"
  }), _vm._v(" Global (Admin) ")]) : _vm._e()])]), _c('div', {
    staticClass: "block-content tab-content"
  }, [_c('ScraperCompanyTab', {
    attrs: {
      "company-form": _vm.companyForm,
      "supplier": _vm.supplier,
      "company-override": _vm.companyOverride
    },
    on: {
      "update:companyForm": function updateCompanyForm($event) {
        _vm.companyForm = $event;
      },
      "update:company-form": function updateCompanyForm($event) {
        _vm.companyForm = $event;
      },
      "update-company-override": _vm.updateCompanyOverride
    }
  }), _c('ScraperAccountsTab', {
    attrs: {
      "supplier": _vm.supplier,
      "account-forms": _vm.accountForms,
      "company-settings": _vm.companySettings,
      "company-override": _vm.companyOverride,
      "account-override": _vm.accountOverride
    },
    on: {
      "update:accountForms": function updateAccountForms($event) {
        _vm.accountForms = $event;
      },
      "update:account-forms": function updateAccountForms($event) {
        _vm.accountForms = $event;
      },
      "update-account-override": _vm.onUpdateAccountOverride
    }
  }), _vm.$auth.isAdmin ? _c('ScraperAdminTab', {
    attrs: {
      "supplier": _vm.supplier,
      "company-override": _vm.companyOverride,
      "supplier-form": _vm.supplierForm
    }
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_vm._v(" Use the below form to enter your login details for this supplier. If you have different login details for certain accounts, you can override these by selecting the account below. ")]), _c('p', {
    staticClass: "font-w600 text-warning"
  }, [_c('strong', [_c('i', {
    staticClass: "fa fa-fw fa-lock"
  })]), _vm._v(" Passwords will be encrypted and stored securely. ")])]);
}]

export { render, staticRenderFns }