var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-pane",
    attrs: {
      "id": "tab-global-".concat(_vm.supplier._id),
      "role": "tabpanel"
    }
  }, [_c('h4', {
    staticClass: "mb-1"
  }, [_vm._v("Global Settings")]), _c('p', [_vm._v("These settings will be the default for all companies and users in Etainabl. Company-level and account-level settings will override these.")]), _vm.companyOverride ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("Some of these settings are being overridden at a company level")]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('FormGroup', {
    attrs: {
      "id": "portal.url-".concat(_vm.supplier._id),
      "error": _vm.error['portal.url'],
      "label": "Portal URL",
      "type": "text",
      "description": "URL to the login page for the supplier portal (if not supplier default)"
    },
    model: {
      value: _vm.supplierForm.url,
      callback: function callback($$v) {
        _vm.$set(_vm.supplierForm, "url", $$v);
      },
      expression: "supplierForm.url"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "portal.scraperEnabled-".concat(_vm.supplier._id),
      "label": "Enable Scraper?",
      "type": "select",
      "options": [{
        label: 'Yes',
        value: 'true'
      }, {
        label: 'No',
        value: 'false'
      }]
    },
    model: {
      value: _vm.supplierForm.scraperEnabled,
      callback: function callback($$v) {
        _vm.$set(_vm.supplierForm, "scraperEnabled", $$v);
      },
      expression: "supplierForm.scraperEnabled"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "portal.scraperId-".concat(_vm.supplier._id),
      "error": _vm.error['portal.scraperId'],
      "label": "Scraper ID",
      "type": "text",
      "description": "Enter the scraper ID to use (if not supplier default)"
    },
    model: {
      value: _vm.supplierForm.scraperId,
      callback: function callback($$v) {
        _vm.$set(_vm.supplierForm, "scraperId", $$v);
      },
      expression: "supplierForm.scraperId"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "portal.concurrentScraper-".concat(_vm.supplier._id),
      "error": _vm.error['portal.concurrent'],
      "label": "Run Concurrently?",
      "type": "select",
      "placeholder": "Not Selected",
      "options": [{
        label: 'Yes',
        value: 'true'
      }, {
        label: 'No',
        value: 'false'
      }]
    },
    model: {
      value: _vm.supplierForm.concurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.supplierForm, "concurrent", $$v);
      },
      expression: "supplierForm.concurrent"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "portal.checkInvoiceFilenames-".concat(_vm.supplier._id),
      "error": _vm.error['portal.checkInvoiceFilenames'],
      "label": "Check Filenames?",
      "type": "select",
      "placeholder": "Not Selected",
      "description": "Select yes to check the database for invoice duplicates based on the filename",
      "options": [{
        label: 'Yes',
        value: 'true'
      }, {
        label: 'No',
        value: 'false'
      }]
    },
    model: {
      value: _vm.supplierForm.checkInvoiceFilenames,
      callback: function callback($$v) {
        _vm.$set(_vm.supplierForm, "checkInvoiceFilenames", $$v);
      },
      expression: "supplierForm.checkInvoiceFilenames"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "portal.supplierRefRequired-".concat(_vm.supplier._id),
      "error": _vm.error['portal.supplierRefRequired'],
      "label": "Require Supplier Ref?",
      "type": "select",
      "placeholder": "Not Selected",
      "description": "Select yes to ignore accounts that don't have a supplier ref",
      "options": [{
        label: 'Yes',
        value: 'true'
      }, {
        label: 'No',
        value: 'false'
      }]
    },
    model: {
      value: _vm.supplierForm.supplierRefRequired,
      callback: function callback($$v) {
        _vm.$set(_vm.supplierForm, "supplierRefRequired", $$v);
      },
      expression: "supplierForm.supplierRefRequired"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "portal.schedule-".concat(_vm.supplier._id),
      "error": _vm.error['portal.schedule'],
      "label": "Daily Schedule",
      "type": "select",
      "placeholder": "Not Selected",
      "description": "Select the time for the connector to run (will automatically run at midnight if none selected)",
      "options": [{
        label: 'Midnight 12AM',
        value: 'midnight'
      }, {
        label: 'Noon 12PM',
        value: 'noon'
      }]
    },
    model: {
      value: _vm.supplierForm.schedule,
      callback: function callback($$v) {
        _vm.$set(_vm.supplierForm, "schedule", $$v);
      },
      expression: "supplierForm.schedule"
    }
  }), _c('button', {
    staticClass: "btn btn-primary mb-3",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClickUpdateSupplier($event);
      }
    }
  }, [_vm.loadingAction.update ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Supplier")])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }