<template>
  <div>
    <FormGroup
      v-for="field in formFields"
      :id="field.id"
      :key="field.id"
      :value="data[field.model]"
      :disabled="field.disabled"
      :error="error[field.error]"
      :label="field.label"
      :type="field.type"
      :options="field.options"
      :description="field.description"
      @input="updateFormData(field.model, $event)"
    />
  </div>
</template>

<script>
import FormGroup from './FormGroup.vue';

export default {
  components: {
    FormGroup
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    error: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formFields: [
        {
          id: 'portal.username',
          model: 'username',
          disabled: false,
          error: 'portal.username',
          label: 'Portal Username',
          type: 'text',
          description: 'The username or email used to login to the supplier portal'
        },
        {
          id: 'portal.password',
          model: 'password',
          disabled: false,
          error: 'portal.password',
          label: 'Portal Password',
          type: 'password',
          description: 'The password used to login to the supplier portal'
        },
        {
          id: 'portal.url',
          model: 'url',
          disabled: false,
          error: 'portal.url',
          label: 'Portal URL',
          type: 'text',
          description: 'URL to the login page for the supplier portal (if not supplier default)'
        },
        {
          id: 'portal.scraperEnabled',
          model: 'scraperEnabled',
          disabled: false,
          error: '',
          label: 'Enable Connector?',
          type: 'select',
          description: '',
          options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' }
          ]
        },
        {
          id: 'portal.scraperStartDate',
          model: 'scraperStartDate',
          disabled: false,
          error: 'portal.scraperStartDate',
          label: 'Connector Invoice Start Date',
          type: 'datePicker',
          description: 'The connector will ignore invoices dated before this date'
        },
        {
          id: 'portal.scraperId',
          model: 'scraperId',
          disabled: false,
          error: 'portal.scraperId',
          label: 'Scraper ID',
          type: 'text',
          description: 'Enter the connector ID to use (if not supplier default)'
        }
      ]
    };
  },
  methods: {
    updateFormData(key, value) {
      const updatedFormData = { ...this.data, [key]: value };
      this.$emit('update:data', updatedFormData);
    }
  }
};
</script>
