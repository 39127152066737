<template>
  <div :id="`tab-global-${supplier._id}`" class="tab-pane" role="tabpanel">
    <h4 class="mb-1">Global Settings</h4>
    <p>These settings will be the default for all companies and users in Etainabl. Company-level and account-level settings will override these.</p>
    <div v-if="companyOverride" class="alert alert-warning">Some of these settings are being overridden at a company level</div>
    <div class="row">
      <div class="col-md-4">
        <FormGroup
          :id="`portal.url-${supplier._id}`"
          v-model="supplierForm.url"
          :error="error['portal.url']"
          label="Portal URL"
          type="text"
          description="URL to the login page for the supplier portal (if not supplier default)"
        />
        <FormGroup
          :id="`portal.scraperEnabled-${supplier._id}`"
          v-model="supplierForm.scraperEnabled"
          label="Enable Scraper?"
          type="select"
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' }
          ]"
        />
        <FormGroup
          :id="`portal.scraperId-${supplier._id}`"
          v-model="supplierForm.scraperId"
          :error="error['portal.scraperId']"
          label="Scraper ID"
          type="text"
          description="Enter the scraper ID to use (if not supplier default)"
        />
        <FormGroup
          :id="`portal.concurrentScraper-${supplier._id}`"
          v-model="supplierForm.concurrent"
          :error="error['portal.concurrent']"
          label="Run Concurrently?"
          type="select"
          placeholder="Not Selected"
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' }
          ]"
        />
        <FormGroup
          :id="`portal.checkInvoiceFilenames-${supplier._id}`"
          v-model="supplierForm.checkInvoiceFilenames"
          :error="error['portal.checkInvoiceFilenames']"
          label="Check Filenames?"
          type="select"
          placeholder="Not Selected"
          description="Select yes to check the database for invoice duplicates based on the filename"
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' }
          ]"
        />
        <FormGroup
          :id="`portal.supplierRefRequired-${supplier._id}`"
          v-model="supplierForm.supplierRefRequired"
          :error="error['portal.supplierRefRequired']"
          label="Require Supplier Ref?"
          type="select"
          placeholder="Not Selected"
          description="Select yes to ignore accounts that don't have a supplier ref"
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' }
          ]"
        />
        <FormGroup
          :id="`portal.schedule-${supplier._id}`"
          v-model="supplierForm.schedule"
          :error="error['portal.schedule']"
          label="Daily Schedule"
          type="select"
          placeholder="Not Selected"
          description="Select the time for the connector to run (will automatically run at midnight if none selected)"
          :options="[
            { label: 'Midnight 12AM', value: 'midnight' },
            { label: 'Noon 12PM', value: 'noon' }
          ]"
        />
        <button class="btn btn-primary mb-3" @click.prevent="onClickUpdateSupplier">
          <span v-if="loadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
          <span v-else><i class="fa fa-check-circle mr-1"></i> Update Supplier</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import FormGroup from '@/components/FormGroup';

export default {
  name: 'ScraperAdminTab',
  components: {
    FormGroup
  },
  props: {
    supplier: {
      type: Object,
      required: true
    },
    companyOverride: {
      type: Boolean,
      required: true
    },
    supplierForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      error: {}
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'supplier/loadingAction'
    })
  },
  methods: {
    ...mapActions({
      updateSupplier: 'supplier/update'
    }),
    ...mapMutations({
      updateSupplierState: 'scraperRun/UPDATE_SUPPLIER_PORTAL'
    }),
    async onClickUpdateSupplier() {
      const updatedSupplier = await this.updateSupplier({
        id: this.supplier._id,
        data: {
          portal: this.supplierForm
        }
      });

      this.updateSupplierState({ supplierId: this.supplier._id, portal: this.supplierForm });

      if (updatedSupplier._id) {
        this.$toasted.success('Supplier portal updated');
      } else {
        this.$toasted.error('Error updating supplier portal');
      }
    }
  }
};
</script>
