var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "scraper-list"
    }
  }, [_c('div', {
    staticClass: "content pt-0"
  }, [_c('FormItem', {
    staticClass: "mb-3",
    attrs: {
      "id": "search",
      "type": "text",
      "placeholder": "Filter suppliers..."
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm._l(_vm.filteredSuppliers, function (supplier) {
    return _c('div', {
      key: supplier._id,
      staticClass: "block block-rounded mb-2"
    }, [_c('div', {
      ref: supplier._id,
      refInFor: true,
      staticClass: "block-header block-header-default d-block",
      attrs: {
        "role": "tab"
      }
    }, [_c('a', {
      staticClass: "font-w600",
      class: {
        collapsed: !_vm.visible[supplier._id]
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickToggle(supplier._id);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('div', {
      attrs: {
        "data-cy": "".concat(supplier.name, "-button").replace(/\s+/g, '-').toLowerCase()
      }
    }, [_c('img', {
      staticClass: "img-avatar img-avatar48 mr-3",
      attrs: {
        "src": supplier.logoUrl
      }
    }), _vm._v(" " + _vm._s(supplier.name) + " ")]), _c('div', [_c('span', {
      staticClass: "badge mr-2",
      class: {
        'badge-success': supplier.isEnabled,
        'badge-danger': !supplier.isEnabled
      }
    }, [_vm._v(_vm._s(supplier.isEnabled ? 'Active' : 'Inactive'))]), _c('span', {}, [_vm._v(_vm._s(supplier.accounts.length))]), _vm._v(" Accounts (" + _vm._s(supplier.accounts.filter(function (a) {
      return !!a.portal;
    }).length) + " overridden) ")])])])]), _vm.visible.includes(supplier._id) ? _c('ScraperManageItem', {
      staticClass: "collapse show",
      attrs: {
        "supplier": supplier
      }
    }) : _vm._e()], 1);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }