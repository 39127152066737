<template>
  <div :id="`tab-company-${supplier._id}`" class="tab-pane show active" :class="{ active: !$auth.isAdmin }" role="tabpanel">
    <h4 class="mb-1">
      <small v-if="$auth.isAdmin" class="text-muted">{{ company.name }}</small> <br />Company Settings
    </h4>

    <p>These settings will be the default for all accounts within your company. Account-level settings will override these.</p>
    <a v-if="!companyOverride" class="font-w600" href="#" @click.prevent="$emit('update-company-override', true)"
      ><div class="block block-rounded bg-light text-center p-3">Currently using supplier defaults, click here to override.</div></a
    >
    <div v-else>
      <div class="row">
        <div class="col-md-4">
          <PortalForm :data="companyForm" :error="error" @update:data="companyForm => $emit('update:companyForm', companyForm)" />
        </div>
      </div>
      <div class="col-md-8">
        <button class="btn btn-primary mb-3 mr-3" @click.prevent="onClickUpdateCompany">
          <span v-if="loadingAction.updateSupplierPortal"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
          <span v-else><i class="fa fa-check-circle mr-1"></i> Update Company</span>
        </button>

        <button class="btn btn-danger mb-3" @click.prevent="onClickClearCompany">
          <span v-if="loadingAction.deleteSupplierPortal"><i class="fa fa-spin fa-spinner mr-1"></i> Clearing...</span>
          <span v-else><i class="fa fa-check-circle mr-1"></i> Clear Settings</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PortalForm from '@/components/PortalForm';

export default {
  name: 'ScraperCompanyTab',
  components: {
    PortalForm
  },
  props: {
    companyForm: {
      type: Object,
      required: true
    },
    supplier: {
      type: Object,
      required: true
    },
    companyOverride: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      error: {},
      disabled: false
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
      loadingAction: 'company/loadingAction'
    })
  },
  methods: {
    ...mapActions({
      updateCompanySupplierPortal: 'company/updateSupplierPortal',
      deleteCompanySupplierPortal: 'company/deleteSupplierPortal',
      getCompany: 'company/get'
    }),
    ...mapMutations({
      updateCompanyState: 'scraperRun/UPDATE_COMPANY_PORTAL'
    }),
    async onClickUpdateCompany() {
      const updatedSupplierPortals = await this.updateCompanySupplierPortal({
        id: this.$auth.companyId,
        supplierId: this.supplier._id,
        data: this.companyForm
      });

      if (updatedSupplierPortals) {
        this.updateCompanyState({ supplierId: this.supplier._id, portal: this.companyForm });
        this.$emit('update-company-override');
        this.$toasted.success('Portal details updated');
      } else {
        this.$toasted.error('Error updating portal details');
      }
    },
    async onClickClearCompany() {
      const updatedSupplierPortals = await this.deleteCompanySupplierPortal({
        id: this.$auth.companyId,
        supplierId: this.supplier._id
      });

      await this.getCompany();

      if (updatedSupplierPortals) {
        this.$emit('update-company-override');
        this.$toasted.success('Portal details cleared');
      } else {
        this.$toasted.error('Error clearing portal details');
      }
    }
  }
};
</script>
