<template>
  <div class="block-content block-content-full">
    <div>
      <div>
        <p>
          Use the below form to enter your login details for this supplier. If you have different login details for certain accounts, you can override
          these by selecting the account below.
        </p>
        <p class="font-w600 text-warning">
          <strong><i class="fa fa-fw fa-lock"></i></strong> Passwords will be encrypted and stored securely.
        </p>
      </div>
      <!-- Tab Links -->
      <ul class="nav nav-tabs nav-tabs-alt" role="tablist">
        <li class="nav-item">
          <button
            :id="`tab-btn-company-${supplier._id}`"
            class="nav-link active"
            data-toggle="tab"
            :data-target="`#tab-company-${supplier._id}`"
            role="tab"
            aria-selected="true"
            :aria-controls="`#tab-company-${supplier._id}`"
          >
            <i class="fa fa-fw fa-users"></i> Company
          </button>
        </li>
        <li class="nav-item ms-auto">
          <button :id="`tab-btn-account-${supplier._id}`" class="nav-link" data-toggle="tab" :data-target="`#tab-account-${supplier._id}`" role="tab">
            <i class="fa fa-fw fa-gauge"></i> Accounts
          </button>
        </li>
        <li class="nav-item">
          <button
            v-if="$auth.isAdmin"
            :id="`tab-btn-global-${supplier._id}`"
            class="nav-link"
            data-toggle="tab"
            :data-target="`#tab-global-${supplier._id}`"
            :aria-controls="`#tab-global-${supplier._id}`"
            role="tab"
          >
            <i class="fa fa-fw fa-globe"></i> Global (Admin)
          </button>
        </li>
      </ul>

      <!-- Tabs -->
      <div class="block-content tab-content">
        <ScraperCompanyTab
          :company-form.sync="companyForm"
          :supplier="supplier"
          :company-override="companyOverride"
          @update-company-override="updateCompanyOverride"
        />

        <ScraperAccountsTab
          :supplier="supplier"
          :account-forms.sync="accountForms"
          :company-settings="companySettings"
          :company-override="companyOverride"
          :account-override="accountOverride"
          @update-account-override="onUpdateAccountOverride"
        />

        <ScraperAdminTab v-if="$auth.isAdmin" :supplier="supplier" :company-override="companyOverride" :supplier-form="supplierForm" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ScraperAccountsTab from './tabs/ScraperAccountsTab';
import ScraperAdminTab from './tabs/ScraperAdminTab';
import ScraperCompanyTab from './tabs/ScraperCompanyTab';

const defaultForm = {
  username: '',
  password: '',
  scraperEnabled: false,
  url: '',
  scraperId: '',
  scraperStartDate: ''
};

export default {
  name: 'ScraperManageItem',
  components: {
    ScraperAccountsTab,
    ScraperAdminTab,
    ScraperCompanyTab
  },
  props: {
    supplier: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      accountForms: {},
      companyForm: {},
      supplierForm: {},
      companyOverride: false,
      accountOverride: {},
      companySettings: {}
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/company'
    })
  },
  mounted() {
    this.updateCompanyOverride();

    this.supplierForm = {
      ...defaultForm,
      ...(this.supplier.portal || {})
    };

    this.companyForm = {
      ...defaultForm,
      ...(this.companySettings ? this.companySettings.portal : {})
    };

    this.supplier.accounts.forEach(account => {
      this.$set(this.accountOverride, account._id, !!account.portal);

      const accountForm = {
        ...defaultForm,
        ...(account.portal || {})
      };

      this.$set(this.accountForms, account._id, accountForm);
    });
  },
  methods: {
    ...mapActions({
      updateSupplier: 'supplier/update'
    }),
    onUpdateAccountOverride(id, propBoolean) {
      this.$set(this.accountOverride, id, propBoolean);
    },
    updateCompanyOverride(override) {
      if (override) return (this.companyOverride = true);
      this.companySettings = this.company.settings.supplierPortals?.find(s => s.supplierId === this.supplier._id) || {};
      this.companyOverride = Object.keys(this.companySettings).length > 0;
    }
  }
};
</script>
