<template>
  <div data-cy="scraper-list">
    <div class="content pt-0">
      <FormItem id="search" v-model="search" type="text" placeholder="Filter suppliers..." class="mb-3" />
      <div v-for="supplier in filteredSuppliers" :key="supplier._id" class="block block-rounded mb-2">
        <div :ref="supplier._id" class="block-header block-header-default d-block" role="tab">
          <a class="font-w600" :class="{ collapsed: !visible[supplier._id] }" href="#" @click.prevent="onClickToggle(supplier._id)"
            ><div class="d-flex align-items-center justify-content-between">
              <div :data-cy="`${supplier.name}-button`.replace(/\s+/g, '-').toLowerCase()">
                <img class="img-avatar img-avatar48 mr-3" :src="supplier.logoUrl" /> {{ supplier.name }}
              </div>

              <div>
                <span class="badge mr-2" :class="{ 'badge-success': supplier.isEnabled, 'badge-danger': !supplier.isEnabled }">{{
                  supplier.isEnabled ? 'Active' : 'Inactive'
                }}</span>
                <span class="">{{ supplier.accounts.length }}</span> Accounts ({{ supplier.accounts.filter(a => !!a.portal).length }}
                overridden)
              </div>
            </div></a
          >
        </div>
        <ScraperManageItem v-if="visible.includes(supplier._id)" class="collapse show" :supplier="supplier" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormItem from '@/components/FormItem';
import ScraperManageItem from './ScraperManageItem';

export default {
  name: 'ScraperManage',
  components: {
    FormItem,
    ScraperManageItem
  },
  data() {
    return {
      disabled: false,
      error: {},
      search: '',
      visible: []
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      company: 'company/company',
      suppliers: 'scraperRun/suppliers'
    }),
    filteredSuppliers() {
      return this.suppliers.filter(s => !this.search || s.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  async mounted() {
    if (this.$route.query.supplierId) {
      this.visible.push(this.$route.query.supplierId);
      this.$nextTick(() => this.scrollTo(this.$route.query.supplierId));
    }
  },
  methods: {
    ...mapActions({
      listAccounts: 'account/list',
      getCompany: 'company/get'
    }),
    scrollTo(selector) {
      const [reference] = this.$refs[selector];
      reference.scrollIntoView({ behavior: 'smooth', alignToBottom: true });
    },
    onClickToggle(supplierId) {
      if (this.visible.includes(supplierId)) {
        this.visible = this.visible.filter(id => id !== supplierId);
      } else {
        this.visible.push(supplierId);
      }
    }
  }
};
</script>
