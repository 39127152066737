<template>
  <!-- Accounts Tab -->
  <div :id="`tab-account-${supplier._id}`" class="tab-pane" role="tabpanel">
    <h4 class="mb-1">Account Settings</h4>
    <p>
      Use the below forms if you have separate login details for individual accounts. These will override any company-level settings you have in
      place.
    </p>

    <div class="pt-2 px-3 mb-3 bg-light block block-rounded">
      <div class="row">
        <div class="col-md-4">
          <FormGroup
            :id="`search-${supplier._id}`"
            v-model="filters.search"
            type="text"
            label="Search"
            placeholder="Search for assets, accounts, MPANs etc..."
            class="mb-3"
          />
        </div>
        <div class="col-md-3">
          <FormGroup
            :id="`search-entity-${supplier._id}`"
            v-model="filters.entityId"
            type="select"
            label="Entity Filter"
            placeholder="Select an entity..."
            class="mb-3"
            :options="entityOptions"
          />
        </div>
        <div class="col-md-3">
          <FormGroup
            :id="`search-asset-${supplier._id}`"
            v-model="filters.assetId"
            type="select"
            label="Asset Filter"
            placeholder="Select an asset..."
            class="mb-3"
            :options="assetOptions"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <FormGroup
            :id="`dropdown-settings-${supplier._id}`"
            v-model="filters.settings"
            type="select"
            label="Settings"
            class="mb-3"
            :options="[
              { value: '', label: 'All' },
              { value: 'account', label: 'Using Account Settings' },
              { value: 'company', label: 'Using Company Settings' },
              { value: 'global', label: 'Using Global Settings' }
            ]"
          />
        </div>
        <div class="col-md-2">
          <FormGroup
            :id="`dropdown-state-${supplier._id}`"
            v-model="filters.state"
            type="select"
            label="State"
            class="mb-3"
            :options="[
              { value: '', label: 'All' },
              { value: 'enabled', label: 'Enabled' },
              { value: 'disabled', label: 'Disabled' }
            ]"
          />
        </div>
      </div>
    </div>
    <div v-if="filteredAccounts.accounts.length === 0" class="alert alert-warning">No accounts found with the selected filters.</div>
    <div v-else :id="`account-accordian-${supplier._id}`">
      <Pagination
        :summary="true"
        class="mb-3"
        :current-page="accountOptions.page"
        :total-pages="Math.ceil(filteredAccounts.pages / 10)"
        :on-click-page="p => (accountOptions.page = p)"
      />
      <div v-for="account in filteredAccounts.accounts" :key="account._id" class="block block-rounded mb-1">
        <div :id="`accordion-${supplier._id}-${account._id}-h`" class="block-header block-header-default d-block" role="tab">
          <a
            class="font-w600"
            data-toggle="collapse"
            :data-parent="`account-accordion-${supplier._id}`"
            :href="`#accordion-${supplier._id}-${account._id}-q`"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <div><UtilityTypeIcon :type="account.type" :text="false" />{{ account.name }}</div>
                <div v-if="account.asset">
                  <small v-if="account.meterPointNumber" class="mr-2"><i class="fa fa-meter fa-fw" /> {{ account.meterPointNumber }}</small>
                  <small class="mr-2"><i class="fa fa-building fa-fw" /> {{ account.asset.siteName }} - {{ account.asset.address | address }}</small>
                  <small v-if="account.supplierRef"><i class="fa fa-lightbulb fa-fw" /> {{ account.supplierRef }}</small>
                </div>

                <div>
                  <span v-if="accountOverride[account._id]" class="mr-2">
                    <span v-if="account.portal?.scraperEnabled" class="badge badge-success">Enabled</span>
                    <span v-else class="badge badge-danger">Disabled</span>
                  </span>

                  <span v-else-if="companyOverride" class="mr-2">
                    <span v-if="companySettings?.portal?.scraperEnabled" class="badge badge-success">Enabled</span>
                    <span v-else class="badge badge-danger">Disabled</span
                    ><span class="badge badge-warning ml-2"><i class="nav-main-link-icon fa fa-users"></i> Using company settings</span>
                  </span>

                  <span v-else class="mr-2">
                    <span v-if="supplier.isGloballyEnabled" class="badge badge-success">Enabled</span>
                    <span v-else class="badge badge-danger">Disabled</span
                    ><span class="badge badge-warning ml-2"><i class="nav-main-link-icon fa fa-globe"></i> Using global settings</span>
                  </span>
                  <span v-if="supplier.portal?.supplierRefRequired && !account.supplierRef" class="badge badge-danger">Missing Supplier Ref</span>
                </div>
              </div>

              <div>
                <small v-if="account.asset" class="font-w600 mr-3"
                  ><i class="fa fa-fw fa-sitemap mr-1"></i> {{ account.asset.entity ? account.asset.entity.legalName : 'N/A' }}</small
                >
                <small v-if="$auth.isAdmin" class="font-w600 mr-3"
                  ><i class="fa fa-fw fa-users mr-1"></i> {{ account.company ? account.company.name : 'N/A' }}</small
                >
              </div>
            </div></a
          >
        </div>
        <div :id="`accordion-${supplier._id}-${account._id}-q`" class="collapse" role="tabpanel" :data-parent="`#account-accordian-${supplier._id}`">
          <div class="block-content">
            <a v-if="!accountOverride[account._id]" class="font-w600" href="#" @click.prevent="$emit('update-account-override', account._id, true)"
              ><div class="block bg-light text-center p-3">Click here to override settings</div></a
            >
            <div v-else>
              <div v-if="account.portal && account.portal.scraperEnabled" class="alert alert-success">
                Portal scraper enabled for this account. Invoices for this account will be scraped on a daily basis.
              </div>
              <h4 class="fw-semibold">{{ account.name }}</h4>

              <AccountInfo :account="account" />

              <div v-if="accountForms[account._id]">
                <FormGroup
                  :id="`portal.username-${account._id}`"
                  v-model="accountForms[account._id].username"
                  :disabled="disabled"
                  :error="error['portal.username']"
                  label="Portal Username"
                  type="text"
                  description="The username or email used to login to the supplier portal"
                />
                <PortalForm :data="accountForms[account._id]" :error="error" @update:data="updateAccountForms(account._id, $event)" />

                <button class="btn btn-primary mb-3 mr-3" @click.prevent="onClickUpdateAccount(account._id)">
                  <span v-if="loadingAction.update"><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span>
                  <span v-else><i class="fa fa-check-circle mr-1"></i> Update Account</span>
                </button>

                <button class="btn btn-danger mb-3" @click.prevent="onClickClearAccount(account._id)">Clear Settings</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lodash from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import AccountInfo from '@/components/accounts/AccountInfo';
import UtilityTypeIcon from '@/components/UtilityTypeIcon';
import FormGroup from '@/components/FormGroup';
import Pagination from '@/components/Pagination';
import PortalForm from '@/components/PortalForm';

export default {
  name: 'ScraperAccountsTab',
  components: {
    AccountInfo,
    UtilityTypeIcon,
    FormGroup,
    Pagination,
    PortalForm
  },
  props: {
    supplier: {
      type: Object,
      required: true
    },
    accountForms: {
      type: Object,
      required: true
    },
    companySettings: {
      type: Object,
      required: true
    },
    companyOverride: {
      type: Boolean,
      required: true
    },
    accountOverride: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      disabled: false,
      error: {},
      filters: {
        assetId: '',
        entityId: '',
        search: '',
        state: '',
        settings: ''
      },
      accountOptions: {
        page: 1
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'account/loadingAction'
    }),
    assetOptions() {
      const assets = (this.supplier.accounts || [])
        .filter(a => a.asset && (!this.filters.entityId || this.filters.entityId === a.entityId))
        .map(a => a.asset);

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      const options = lodash.uniqBy(assets, '_id').map(asset => ({
        label: asset.siteName,
        value: asset._id
      }));

      return [
        {
          label: 'All',
          value: ''
        },
        ...options
      ];
    },
    entityOptions() {
      const entities = (this.supplier.accounts || []).filter(a => a.asset && a.asset.entity).map(a => a.asset.entity);

      entities.sort((a, b) => a.legalName.localeCompare(b.legalName));

      const options = lodash.uniqBy(entities, '_id').map(entity => ({
        label: entity.legalName,
        value: entity._id
      }));

      return [
        {
          label: 'All',
          value: ''
        },
        ...options
      ];
    },
    filteredAccounts() {
      if (!this.supplier.accounts || this.supplier.accounts.length === 0) return [];

      const filteredAccounts = this.supplier.accounts
        .filter(a => !this.filters.entityId || this.filters.entityId === a.asset?.entityId)
        .filter(a => !this.filters.assetId || this.filters.assetId === a.assetId)
        .filter(a => {
          // Search
          const haystacks = [a.name, a.meterPointNumber, a.meterSerialNumber];
          if (a.asset) haystacks.push(a.asset.siteName, a.asset.addressString);
          if (a.asset && a.asset.entity) haystacks.push(a.asset.entity.legalName);
          return !this.filters.search || haystacks.some(h => (h || '').toLowerCase().includes(this.filters.search.toLowerCase()));
        })
        .filter(a => {
          // state
          if (this.filters.state === '') return true;

          if (this.filters.state === 'enabled') {
            if (this.accountOverride[a._id]) return a.portal && a.portal.scraperEnabled;
            if (this.companyOverride) return this.companySettings && this.companySettings.portal.scraperEnabled;
            return this.supplier.isGloballyEnabled;
          }

          if (this.accountOverride[a._id]) return !a.portal || !a.portal.scraperEnabled;
          if (this.companyOverride) return !this.companySettings || !this.companySettings.portal.scraperEnabled;
          return !this.supplier.isGloballyEnabled;
        })
        .filter(a => {
          // settings
          if (this.filters.settings === '') return true;

          const override = this.accountOverride[a._id];

          if (this.filters.settings === 'account') return override;
          if (override) return false;

          if (this.filters.settings === 'company') return this.companyOverride;
          if (this.companyOverride) return false;

          return true;
        });

      // Sort by scraper enabled, then by asset siteName
      filteredAccounts.sort((a, b) => {
        if (a.portal && a.portal.scraperEnabled && (!b.portal || !b.portal.scraperEnabled)) return -1;
        if (b.portal && b.portal.scraperEnabled && (!a.portal || !a.portal.scraperEnabled)) return 1;
        return (a.asset ? a.asset.siteName : 'Z').localeCompare(b.asset ? b.asset.siteName : 'Z');
      });
      return {
        pages: filteredAccounts.length,
        accounts: filteredAccounts.slice((this.accountOptions.page - 1) * 10, (this.accountOptions.page - 1) * 10 + 10)
      };
    }
  },
  methods: {
    ...mapActions({
      updateAccount: 'account/update'
    }),
    ...mapMutations({
      updateAccountState: 'scraperRun/UPDATE_SUPPLIER_ACCOUNT'
    }),
    updateAccountForms(id, value) {
      this.$emit('update:accountForms', { ...this.accountForms, [id]: value });
    },
    async onClickUpdateAccount(id) {
      const updatedAccount = await this.updateAccount({
        id,
        data: {
          portal: this.accountForms[id]
        }
      });

      if (updatedAccount._id) {
        this.updateAccountState({ supplierId: this.supplier._id, portal: this.accountForms[id], accountId: id });
        this.$toasted.success('Account portal details updated');
      } else {
        this.$toasted.error('Error updating account portal details');
      }
    },
    async onClickClearAccount(id) {
      const updatedAccount = await this.updateAccount({
        id,
        data: { $unset: { portal: '' } }
      });

      if (updatedAccount._id) {
        this.$toasted.success('Account portal details cleared');
        this.$emit('update-account-override', id, false);
      } else {
        this.$toasted.error('Error clearing account portal details');
      }
    }
  }
};
</script>
